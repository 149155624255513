import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";

export interface EditableTextProps {
    text: string;
    onSave: (s: string) => void;
}

export const EditableText: React.FC<EditableTextProps> = (props) => {
    const [inEditionMode, setEditionMode] = useState(false);
    const [text, setTextValue] = useState(props.text);
    const [formErrors, setErrors] = useState({} as any);

    const setText = (text: string) => {
        setTextValue(text);
        if (!!formErrors["text"]) {
            setErrors({
                ...formErrors,
                ["text"]: null
            })
        }
    }

    const validateForm = () => {
        const newErrors = {} as any;
        if (!text || text === '') newErrors.text = 'Introduce un nombre de componente válido';
        return newErrors;
    }

    const handleSaveChanges = async (e?: any) => {
        if (!!e) { e.preventDefault(); }
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            setEditionMode(false);
            props.onSave(text);
        }
    }


    const handleCancelChanges = () => {
        setText(props.text);
        setErrors({});
        setEditionMode(false);
    }

    return (
        <Row>
            <Col md={6}>
                {!inEditionMode && <h3>{text}</h3>}
                {inEditionMode && <Form onSubmit={handleSaveChanges}>
                    <Row className="mb-3">
                        <Form.Label column sm="2"><b>Nombre: </b></Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                placeholder={text !== '' ? text : 'Nombre del componente'}
                                value={text}
                                isInvalid={!!formErrors.text}
                                onChange={(e) => setText(e.target.value)}
                                readOnly={!inEditionMode} plaintext={!inEditionMode} />
                            <Form.Control.Feedback type='invalid'>{formErrors.text}</Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form>}
            </Col>
            {!inEditionMode && <Col md={1}>
                <button onClick={() => setEditionMode(true)} className="btn btn-outline-warning float-right">Editar</button>
            </Col>}
            {inEditionMode && <Col md={4}>
                <button className="btn btn-outline-success mr-3" onClick={handleSaveChanges}>Guardar</button>
                <button className="btn btn-outline-secondary" onClick={handleCancelChanges}>Cancelar</button>
            </Col>}
        </Row>
    )
}