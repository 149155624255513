import { useState } from "react";
import { Container, Col, Button, Row, Form, Card } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { CategoryInfo, CollectionInfo, updateCategoryAction, UpdateCategoryActionPayload, updateCollectionCategoryImage } from "../../app/features/collections/collectionsSlice";
import { useAppDispatch } from "../../app/hooks";
import CollectionService from "../../services/collection.service";

interface CategoryDetailsProps {
    categoryInfo: CategoryInfo;
    onClickReturn: () => void;
}

const CategoryDetails: React.FC<CategoryDetailsProps> = (props) => {
    const [inEditionMode, setEditionMode] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState({
        name: props.categoryInfo.name,
        image: ""
    });
    const [formErrors, setErrors] = useState({} as any);
    const setFormField = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value
        });
        if (!!formErrors[field]) {
            setErrors({
                ...formErrors,
                [field]: null
            })
        }
    }

    const validateForm = () => {
        const { name } = formData;
        const newErrors = {} as any;
        if (!name || name === '') newErrors.name = 'Introduce un nombre de categoría válido';
        return newErrors;
    }

    const handleSaveChanges = async (e?: any) => {
        if (!!e) { e.preventDefault(); }
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            if (formData.image != "") {
                setUploadInProgress(true);
                try {
                    const categoryInfo = await CollectionService.uploadCategoryImage(props.categoryInfo.collectionId, props.categoryInfo.id, formData.image as any);
                    dispatch(updateCollectionCategoryImage(categoryInfo));
                } finally {
                    setUploadInProgress(false);
                }
            }
            dispatch(updateCategoryAction({
                name: formData.name,
                collection_id: props.categoryInfo.collectionId,
                category_id: props.categoryInfo.id
            } as UpdateCategoryActionPayload));
            setFormField("image", "");
            setEditionMode(false);
        }
    }

    const handleCancelChanges = () => {
        setFormData({
            name: props.categoryInfo.name,
            image: ""
        });
        setErrors({});
        setEditionMode(false);
    }

    return (
        <span>
            <Row className="mb-3">
                <Col xs={5}><h3>{formData.name}</h3></Col>
                <Col xs={7}><button onClick={props.onClickReturn} className="btn btn-outline-success float-right"><Icon.ArrowLeftCircle /> Volver</button></Col>
            </Row>
            <Container className="mb-3 pb-3 border-bottom">
                <Row>
                    <Col md={10} style={{ textAlign: 'justify' }}>
                        <Form onSubmit={handleSaveChanges}>
                            <Row className="mb-3">
                                <Form.Label column sm="2"><b>Nombre: </b></Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder={formData.name !== '' ? formData.name : 'Nombre de la categoría'}
                                        value={formData.name}
                                        isInvalid={!!formErrors.name}
                                        onChange={(e) => setFormField('name', e.target.value)}
                                        readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                    <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
                                </Col>
                            </Row>
                            {(!!props.categoryInfo.image || inEditionMode) && <Row className="mb-3">
                                <Form.Label column sm="2"><b>Imagen: </b></Form.Label>
                                <Col sm={10} className="pt-2 pb-5">
                                    {!inEditionMode && <Card><Card.Img variant="top" src={props.categoryInfo.image} /></Card>}
                                    {inEditionMode && <Form.Control type="file" onChange={(e) => setFormField('image', (e.target as any).files[0])} readOnly={!inEditionMode} />}
                                </Col>
                            </Row>}
                        </Form>
                    </Col>
                    <Col md={2}>
                        {!inEditionMode && <button onClick={() => setEditionMode(true)} className="btn btn-outline-warning float-right">Editar</button>}
                        {inEditionMode && <>
                            <Row className="mb-3">
                                <button disabled={uploadInProgress} className="btn btn-outline-success" onClick={() => handleSaveChanges()}>Guardar</button>
                            </Row>
                            <Row>
                                <button disabled={uploadInProgress} className="btn btn-outline-secondary" onClick={() => handleCancelChanges()}>Cancelar</button>
                            </Row>
                        </>}
                    </Col>
                </Row>
            </Container>
        </span>
    )
}

export default CategoryDetails;