import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function FooterRA() {
    return (
        <Container className="border-top pt-3 mt-5 pb-3">
            <Row>
                <Col>
                    <p>© HabitatRA - 2023</p>
                </Col>
                <Col className="text-right font-weight-light">
                    <p>¿Tienes Problemas? Contacta con nosotros en <a className='alert-link'>soporte@habitat-ra.com</a></p>
                </Col>
            </Row>
        </Container>
    );
}

export default FooterRA;