import { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { selectCategories, selectCollections } from '../../app/features/collections/collectionsSlice';
import { useAppSelector } from '../../app/hooks';
import CategorySelector from '../collections/CategorySelector';

interface MaterialSearchBarProps {
    handleFilter: (word: string) => void;
}

const MaterialSearchBar: React.FC<MaterialSearchBarProps> = (props) => {
    const [searchName, setSearchName] = useState("");

    useEffect(() => {
        props.handleFilter(
            searchName
        );
    }, [searchName])

    const handleNameSelected = (name: string) => {
        setSearchName(name);
    }

    return (
        <Row className="mb-3">
            <Col md={12} className="mt-3">
                <Form.Control value={searchName} onChange={(e) => handleNameSelected(e.target.value)} placeholder='Búsqueda de materiales' />
            </Col>
        </Row>
    );
}

export default MaterialSearchBar;
