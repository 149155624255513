import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { MutableRefObject, Suspense, useRef, useState } from "react";
import React from "react";
import { HabitatLoader } from "../common/HabitatLoader";
import * as Icon from 'react-bootstrap-icons';
import { ModelInfo } from "../../app/features/models/modelsSlice";
import modelsService from "../../services/models.service";
import { Html, softShadows, OrbitControls, Gltf, AccumulativeShadows, Center, Environment, RandomizedLight } from "@react-three/drei";

export interface ModelViewerProps {
    model: ModelInfo;
    variations?: Map<number, string>;
}

softShadows();

function Model(props: { model: ModelInfo, variations: Map<number, string> }) {
    return (
        <group {...props} dispose={null}>
            <Gltf dispose={null} src={modelsService.getModelGLTFURI(props.model.id, props.variations, props.model.lastUpload!)} receiveShadow castShadow />
        </group>
    )
}

const ModelViewer: React.FC<ModelViewerProps> = (props) => {
    const [mouseDown, setMouseDown] = useState(false);
    const [mouseOver, setMouseOver] = useState(false);

    const DisableRender = () => useFrame(() => null, 1000);

    const chartRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null);

    const handleChartDownload = () => {
        const chartCanvas = chartRef!.current;
        if (chartCanvas) {
          const url = chartCanvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.download = "model.png";
          link.href = url;
          link.click();
        }
        };
    return (
        <div className="border-bottom pb-3 mb-3">
            <Canvas 
            gl={{ preserveDrawingBuffer: true }} 
            ref={(ref) => chartRef.current = ref} 
            style={{ height: "700px", cursor: mouseDown ? "grabbing" : "grab" }} 
            className="pb-3" shadows
            onMouseDown={() => setMouseDown(true)}
            onMouseUp={() => setMouseDown(false)}
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            camera={{ position: [0, 1.5, 6], fov: 20 }}>
            {false && <DisableRender />}
            {<Suspense fallback={<Html><p>Cargando...</p><HabitatLoader /></Html>}>

                {/* Our main source of light, also casting our shadow */}
                <directionalLight
                    castShadow
                    position={[0, 10, 5]}
                    intensity={1}
                    shadow-mapSize-width={1024}
                    shadow-mapSize-height={1024}
                    shadow-camera-far={50}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10} />
                {false && <>
                <directionalLight
                    castShadow
                    position={[10, 10, 5]}
                    intensity={0.02}
                    shadow-mapSize-width={256}
                    shadow-mapSize-height={256}
                    shadow-camera-far={50}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10} />
                <directionalLight
                    castShadow
                    position={[-10, 10, 5]}
                    intensity={0.02}
                    shadow-mapSize-width={256}
                    shadow-mapSize-height={256}
                    shadow-camera-far={50}
                    shadow-camera-left={-10}
                    shadow-camera-right={10}
                    shadow-camera-top={10}
                    shadow-camera-bottom={-10} />
                    </>}
                <group>
                    <Center top>
                        <Model model={props.model} variations={props.variations ? props.variations : new Map()} />
                    </Center>
                    <mesh
                        rotation={[-Math.PI / 2, 0, 0]}
                        position={[0, -0.01, 0]}
                        receiveShadow>
                        <planeBufferGeometry attach='geometry' args={[10, 10]} />
                        <shadowMaterial attach='material' opacity={0.05} />
                    </mesh>
                    {false && <AccumulativeShadows temporal frames={200} scale={12}>
                        <RandomizedLight amount={2} radius={5} ambient={0.8} intensity={0.7} position={[-1, 5, -10]} />
                    </AccumulativeShadows>}
                </group>
                <Environment files="lebombo_1k.hdr" />
            </Suspense>}
            <OrbitControls target={[0, 0.5, 0]} />
        </Canvas><button
            className="btn btn-outline-success"
            onClick={() => handleChartDownload()}
        ><Icon.Camera/></button></div>

    );
}

export default ModelViewer;