import { useRef, useState, useEffect } from "react";
import { Button, Col, Container, Navbar, Row, Tab, Tabs } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import CategoryList from "../components/collections/CategoryList";
import { HabitatLoader } from "../components/common/HabitatLoader";
import * as Icon from 'react-bootstrap-icons';
import { selectCollections, selectCollectionsStatus, createCollectionAction, loadCollectionsAction } from "../app/features/collections/collectionsSlice";
import CollectionDetails from "../components/collections/CollectionDetails";

function CollectionsPage() {
    const dataLoaded = useRef(false);
    const collections = useAppSelector(selectCollections);
    const [creatingCollection, setCreatingCollection] = useState(false);
    const [openCollection, setOpenCollection] = useState('');
    const loadingStatus = useAppSelector(selectCollectionsStatus);
    const dispatch = useAppDispatch();

    // Component Did Mount Hook
    useEffect(() => {
        if (!dataLoaded.current) {
            dispatch(loadCollectionsAction());
            dataLoaded.current = true;
        }
    }, []);


    const handleCreateCollection = async () => {
        setCreatingCollection(true);
        await dispatch(createCollectionAction({ name: "Nueva Colección" })).unwrap();
        setCreatingCollection(false);
    }

    return (
        <Container>
            <h1 className="border-bottom mb-3 pb-3">Colecciones</h1>
            {loadingStatus === 'loading' && <HabitatLoader />}
            {loadingStatus !== 'loading' &&
                <Container>
                    <Row>
                        <Col xs={12}><button disabled={creatingCollection} onClick={handleCreateCollection} className="btn btn-outline-success float-right">Añadir Colección <Icon.PlusCircle /></button></Col>
                    </Row>
                    {collections.length > 0 &&
                        <Tabs
                            id="collections-tabs"
                            activeKey={openCollection}
                            onSelect={(k) => setOpenCollection(k!)}
                            className="mb-3"
                        >
                            {collections.map(c =>
                                <Tab key={c.id} eventKey={c.id} title={c.name}>
                                    <CollectionDetails collectionInfo={c} />
                                    <CategoryList collection_id={c.id} />
                                </Tab>
                            )}
                        </Tabs>}
                    {collections.length === 0 && <p>No se han encontrado colecciones</p>}
                </Container>
            }
        </Container>
    );
}

export default CollectionsPage;