import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { CategoryInfo, selectCategories, selectCollections } from "../../app/features/collections/collectionsSlice";
import { useAppSelector } from "../../app/hooks";

export interface CategorySelectorProps {
    onSelect: (collection: string, category: string) => void;
}

const CategorySelector: React.FC<CategorySelectorProps> = (props) => {
    const collections = useAppSelector(selectCollections);
    const categories = useAppSelector(selectCategories);
    const [selectedCollection, setSelectedCollection] = useState("-1");
    const [selectedCategory, setSelectedCategory] = useState("-1");
    const [visibleCategories, setVisibleCategories] = useState([] as CategoryInfo[]);

    useEffect(() => {
        props.onSelect(
            selectedCollection != "-1" ? selectedCollection : "",
            selectedCategory != "-1" ? selectedCategory : ""
        );
    }, [selectedCollection, selectedCategory])

    const handleCollectionSelected = (collection: string) => {
        setSelectedCategory("-1");
        if(collection !== "-1"){
            setVisibleCategories(categories.filter(e => e.collectionId === collection));
        }
        setSelectedCollection(collection);
    }

    const handleCategorySelected = (category: string) => {
        setSelectedCategory(category);
    }

    return (
        <Row>
            <Col sm={12} md={6}>
                <Form.Select onChange={(e) => handleCollectionSelected(e.target.value)}>
                    <option value={"-1"}>Todas las Colecciones</option>
                    {collections.map(c => (
                        <option key={c.id} value={c.id}>{c.name}</option>
                    ))}
                </Form.Select>
            </Col>
            <Col sm={12} md={6}>
                <Form.Select value={selectedCategory} onChange={(e) => handleCategorySelected(e.target.value)} disabled={selectedCollection === "-1"}>
                    <option value={"-1"}>Todas las Categorias</option>
                    {visibleCategories.map(c => (
                        <option key={c.id} value={c.id}>{c.name}</option>
                    ))}
                </Form.Select>
            </Col>
        </Row>
    )
}

export default CategorySelector;