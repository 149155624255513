import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Models from './pages/Models';
import Home from './pages/Home';
import NavbarMenu from './components/common/Navbar';
import FooterRA from './components/common/FooterRA';
import CollectionsPage from './pages/Collections';
import LoginPage from './pages/Login';
import ProtectedRoute from './components/common/GuardedRoute';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { loadAuth, selectLoaded } from './app/features/auth/authSlice';
import { HabitatLoader } from './components/common/HabitatLoader';
import Materials from './pages/Materials';

function App() {
  const dispatch = useAppDispatch();
  const authLoaded = useAppSelector(selectLoaded);

  // Component Did Mount Hook
  useEffect(() => {
    dispatch(loadAuth())
  }, []);

  return (
    <>
      {!authLoaded && <HabitatLoader/>}
      {authLoaded && <>
        <NavbarMenu />
        <Routes>
          <Route path="/" element={<ProtectedRoute outlet={<Home />} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/collections" element={<ProtectedRoute outlet={<CollectionsPage />} />} />
          <Route path="/models" element={<ProtectedRoute outlet={<Models />} />} />
          <Route path="/models/:modelId" element={<ProtectedRoute outlet={<Models />} />} />
          <Route path="/materials" element={<ProtectedRoute outlet={<Materials />} />} />
          <Route path="/materials/:materialId" element={<ProtectedRoute outlet={<Materials />} />} />
        </Routes>
        <FooterRA />
      </>}
    </>
  );
}

export default App;
