import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { selectCategories, selectCollections } from '../../app/features/collections/collectionsSlice';
import { useAppSelector } from '../../app/hooks';
import CategorySelector from '../collections/CategorySelector';

interface ModelSearchBarProps {
    handleFilter: (word?: string, collection?: string, category?: string) => void;
}

const ModelSearchBar: React.FC<ModelSearchBarProps> = (props) => {
    const [searchName, setSearchName] = useState("");
    const [selectedCollection, setSelectedCollection] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");

    useEffect(() => {
        props.handleFilter(
            searchName != "" ? searchName : undefined,
            selectedCollection != "" ? selectedCollection : undefined,
            selectedCategory != "" ? selectedCategory : undefined
        );
    }, [searchName, selectedCollection, selectedCategory])

    const handleCategorySelected = (collection: string, category: string) => {
        setSelectedCategory(category);
        setSelectedCollection(collection);
    }

    const handleNameSelected = (name: string) => {
        setSearchName(name);
    }

    return (
        <Row className="mb-3">
            <Col sm={12} md={6} className="mt-3">
                <Form.Control value={searchName} onChange={(e) => handleNameSelected(e.target.value)} placeholder='Nombre del modelo' />
            </Col>
            <Col sm={12} md={6} className="mt-3">
                <CategorySelector onSelect={handleCategorySelected} />
            </Col>
        </Row>
    );
}

export default ModelSearchBar;
