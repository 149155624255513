import { useEffect, useState } from "react";
import { Container, Col, Button, Row, Form, Card } from "react-bootstrap";
import { CollectionInfo, updateCollectionAction, UpdateCollectionActionPayload, updateCollectionImage } from "../../app/features/collections/collectionsSlice";
import { useAppDispatch } from "../../app/hooks";
import CollectionService from "../../services/collection.service";

interface CollectionDetailsProps {
    collectionInfo: CollectionInfo;
}

const CollectionDetails: React.FC<CollectionDetailsProps> = (props) => {
    const [inEditionMode, setEditionMode] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState({
        name: props.collectionInfo.name,
        description: props.collectionInfo.description,
        visibleApp: props.collectionInfo.visibleApp,
        image: ""
    });
    const [formErrors, setErrors] = useState({} as any);
    const setFormField = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value
        });
        if (!!formErrors[field]) {
            setErrors({
                ...formErrors,
                [field]: null
            })
        }
    }

    const validateForm = () => {
        const { name } = formData;
        const newErrors = {} as any;
        if (!name || name === '') newErrors.name = 'Introduce un nombre de colección válido';
        return newErrors;
    }

    const handleSaveChanges = async (e?: any) => {
        if (!!e) { e.preventDefault(); }
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            if (formData.image != "") {
                setUploadInProgress(true);
                try {
                    const collectionInfo = await CollectionService.uploadCollectionImage(props.collectionInfo.id, formData.image as any);
                    dispatch(updateCollectionImage(collectionInfo));
                } finally {
                    setUploadInProgress(false);
                }
            }
            dispatch(updateCollectionAction({
                name: formData.name,
                description: formData.description,
                visibleApp: formData.visibleApp,
                collectionId: props.collectionInfo.id
            } as UpdateCollectionActionPayload));
            setFormField("image", "");
            setEditionMode(false);
        }
    }

    const handleCancelChanges = () => {
        setFormData({
            name: props.collectionInfo.name,
            visibleApp: props.collectionInfo.visibleApp,
            description: props.collectionInfo.description,
            image: ""
        });
        setErrors({});
        setEditionMode(false);
    }

    return (
        <span>
            <h3>Detalles de la colección:</h3>
            <Container className="mb-3 pb-3 border-bottom">
                <Row>
                    <Col md={10} style={{ textAlign: 'justify' }}>
                        <Form onSubmit={handleSaveChanges}>
                            <Row className="mb-3">
                                <Form.Label column sm="2"><b>Nombre: </b></Form.Label>
                                <Col sm="10">
                                    <Form.Control
                                        type="text"
                                        placeholder={formData.name !== '' ? formData.name : 'Nombre de la colección'}
                                        value={formData.name}
                                        isInvalid={!!formErrors.name}
                                        onChange={(e) => setFormField('name', e.target.value)}
                                        readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                    <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Form.Label column sm="2"><b>Visible en App: </b></Form.Label>
                                <Col sm={10} className="pt-2 pb-2">
                                    <Form.Check
                                        type="switch"
                                        id="visibilidad-switch"
                                        disabled={!inEditionMode}
                                        defaultChecked={formData.visibleApp}
                                        value={formData.visibleApp ? 'off' : 'on'}
                                        onChange={(e) => setFormField('visibleApp', e.target.value === 'on' ? true : false)}
                                    />
                                </Col>
                            </Row>
                            {(!!props.collectionInfo.image || inEditionMode) && <Row className="mb-3">
                                <Form.Label column sm="2"><b>Imagen: </b></Form.Label>
                                <Col sm={10} className="pt-2 pb-5">
                                    {!inEditionMode && <Card><Card.Img variant="top" src={props.collectionInfo.image} /></Card>}
                                    {inEditionMode && <Form.Control type="file" onChange={(e) => setFormField('image', (e.target as any).files[0])} readOnly={!inEditionMode} />}
                                </Col>
                            </Row>}
                            {(!!formData.description || inEditionMode) && <Row className="mb-3">
                                <Form.Label column sm="2"><b>Descripción: </b></Form.Label>
                                <Col sm="10">
                                    <Form.Control as="textarea" rows={3}
                                        placeholder={!!formData.description ? formData.description : 'Descripción de la colección'}
                                        value={formData.description}
                                        isInvalid={!!formErrors.description}
                                        onChange={(e) => setFormField('description', e.target.value)}
                                        readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                    <Form.Control.Feedback type='invalid'>{formErrors.description}</Form.Control.Feedback>
                                </Col>
                            </Row>}
                        </Form>
                    </Col>
                    <Col md={2}>
                        {!inEditionMode && <button onClick={() => setEditionMode(true)} className="btn btn-outline-warning float-right">Editar</button>}
                        {inEditionMode && <>
                            <Row className="mb-3">
                                <button className="btn btn-outline-success" disabled={uploadInProgress} onClick={() => handleSaveChanges()}>Guardar</button>
                            </Row>
                            <Row>
                                <button className="btn btn-outline-secondary" disabled={uploadInProgress} onClick={() => handleCancelChanges()}>Cancelar</button>
                            </Row>
                        </>}
                    </Col>
                </Row>
            </Container>
        </span>
    )
}

export default CollectionDetails;