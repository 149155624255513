import { ThunkDispatch, Action } from "@reduxjs/toolkit";
import { onAuthStateChanged, User, UserCredential } from "firebase/auth";
import { authLoaded, AuthState } from "../app/features/auth/authSlice";
import { CollectionsState } from "../app/features/collections/collectionsSlice";
import { auth, signInWithEmailAndPassword } from "./firebase";

class AuthService {
  public async login(email: string, password: string): Promise<UserCredential> {
    return await signInWithEmailAndPassword(auth, email, password);
  }

  public async logout() {
    return await auth.signOut();
  }

  public getUser(): User | null {
    return auth.currentUser;
  }

  public subscribeAuth(dispatch: ThunkDispatch<{ collections: CollectionsState; auth: AuthState; }, unknown, Action<string>>) {
    onAuthStateChanged(auth, (user) => {
      dispatch(authLoaded(user ? user.uid : user));
    });
  }
}

const authService = new AuthService();

export async function authHeader() {
  const user = auth.currentUser;
  if (user) {
    return { Authorization: "Bearer " + (await user.getIdToken()) };
  } else {
    return {};
  }
}

export default authService;
