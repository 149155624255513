import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAJx1M_ML-DSh4qlFGpKhVnBSX74AsmddY",
  authDomain: "habitat-ra-pruebas.firebaseapp.com",
  projectId: "habitat-ra-pruebas",
  storageBucket: "habitat-ra-pruebas.appspot.com",
  messagingSenderId: "240219838928",
  appId: "1:240219838928:web:5a3423bcd5b9bda15e01d5",
  measurementId: "G-7YF1QZXTH7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence);
export { app, analytics, auth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword };
