import { AccumulativeShadows, Center, Environment, Gltf, Html, OrbitControls, RandomizedLight, softShadows } from "@react-three/drei";
import { useFrame, Canvas } from "@react-three/fiber";
import { useState, MutableRefObject, useRef, Suspense } from "react";
import { MaterialInfo } from "../../services/materials.service";
import * as Icon from 'react-bootstrap-icons';
import { HabitatLoader } from "../common/HabitatLoader";
import ModelsService from "../../services/models.service";

export interface MaterialViewerProps {
    material: MaterialInfo;
}

softShadows();

function Material(props: { material: MaterialInfo}) {
    const ref = useRef() as any;
    useFrame((state) => {
        const t = state.clock.getElapsedTime()
        // ref.current.rotation.x = 1 - Math.cos(t / 4) / 8
        // ref.current.rotation.y = 1 - Math.sin(t / 4) / 8
        // ref.current.rotation.y = (1 - Math.sin(t / 2)) / 8
        // ref.current.rotation.z = (1 - Math.sin(t / 2)) / 8
        ref.current.rotation.x = (Math.cos(t / 4)) / 5
        ref.current.rotation.z = (Math.sin(t / 4)) / 4
        ref.current.rotation.y = (Math.cos(t / 4))
        ref.current.position.y = (4.5 - Math.sin(t / 3)) / 10
    })
    return (
        <group ref={ref}  {...props} dispose={null}>
            <Gltf dispose={null} src={ModelsService.getMaterialPreviewURL(props.material)} receiveShadow  />
        </group>
    )
}

const MaterialViewer: React.FC<MaterialViewerProps> = (props) => {
    const [mouseDown, setMouseDown] = useState(false);

    return (
        <div>
            <Canvas
                gl={{ preserveDrawingBuffer: true }}
                style={{ height: "250px", cursor: mouseDown ? "grabbing" : "grab" }}
                className="pb-3" shadows
                onMouseDown={() => setMouseDown(true)}
                onMouseUp={() => setMouseDown(false)}
                camera={{ position: [0, 2, 4], fov: 20 }}>
                <Suspense fallback={<Html><p>Cargando...</p><HabitatLoader /></Html>}>

                    {/* Our main source of light, also casting our shadow */}
                    <directionalLight
                        castShadow
                        position={[0, 10, 5]}
                        intensity={1}
                        shadow-mapSize-width={1024}
                        shadow-mapSize-height={1024}
                        shadow-camera-far={50}
                        shadow-camera-left={-10}
                        shadow-camera-right={10}
                        shadow-camera-top={10}
                        shadow-camera-bottom={-10} />
                    {!mouseDown && false && <><directionalLight
                        castShadow
                        position={[10, 10, 5]}
                        intensity={0.02}
                        shadow-mapSize-width={256}
                        shadow-mapSize-height={256}
                        shadow-camera-far={50}
                        shadow-camera-left={-10}
                        shadow-camera-right={10}
                        shadow-camera-top={10}
                        shadow-camera-bottom={-10} /><directionalLight
                            castShadow
                            position={[-10, 10, 5]}
                            intensity={0.02}
                            shadow-mapSize-width={256}
                            shadow-mapSize-height={256}
                            shadow-camera-far={50}
                            shadow-camera-left={-10}
                            shadow-camera-right={10}
                            shadow-camera-top={10}
                            shadow-camera-bottom={-10} /></>}
                    <group>
                        <Center top>
                            <Material material={props.material}/>
                        </Center>
                    </group>
                    <OrbitControls target={[0, 0.75, 0]} />
                    <Environment files="lebombo_1k.hdr" />
                </Suspense>
            </Canvas>
        </div>
    );
}

export default MaterialViewer;