import { Component } from "react";
import { Container } from "react-bootstrap";
import { Grid } from "react-loader-spinner";

export class HabitatLoader extends Component {
    render() {
        return (
            <Container style={{ margin: "50px auto", width: "100px" }}>
                <Grid
                    height="100"
                    width="100"
                    color="grey"
                    ariaLabel="grid-loading"
                    radius="12.5"
                />
            </Container>
        )
    }
}