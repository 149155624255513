import { useEffect, useRef, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { loadCollectionsAction, selectCollectionsStatus } from '../app/features/collections/collectionsSlice';
import { createModelAction, loadModelsAction, ModelInfo, selectModels, selectModelsStatus } from '../app/features/models/modelsSlice';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { HabitatLoader } from '../components/common/HabitatLoader';
import * as Icon from 'react-bootstrap-icons';
import ModelGrid from '../components/models/ModelGrid';
import ModelSearchBar from '../components/models/ModelSearchBar';
import ModelDetails from '../components/models/ModelDetails';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loadMaterialsAction, selectMaterials, selectMaterialsStatus } from '../app/features/materials/materialsSlice';
import { MaterialsGrid } from '../components/materials/MaterialsGrid';
import { ModelMaterialsDetails } from '../components/models/ModelMaterialsDetails';

function Models() {
  const dataLoaded = useRef(false);
  const dispatch = useAppDispatch();

  const loadingCollectionsStatus = useAppSelector(selectCollectionsStatus);
  const loadingModelsStatus = useAppSelector(selectModelsStatus);
  const loadingMaterialsStatus = useAppSelector(selectMaterialsStatus);
  const loadingStatus = loadingCollectionsStatus === "loading" || loadingModelsStatus === "loading" || loadingMaterialsStatus === "loading";

  const { modelId } = useParams();

  const [creatingModel, setCreatingModel] = useState(false);
  const [selectedModelId, setSelectedModelId] = useState(modelId ? modelId : "");

  const [selectedFilter, setSelectedFilter] = useState({
    word: "",
    collection: "",
    category: ""
  });

  const models = useAppSelector(selectModels);

  const selectedModel = selectedModelId !== "" ? models.find(m => m.id === selectedModelId)! : null;

  const filterModels = () => {
    let modelsFiltered = models;
    if (selectedFilter.word !== "") {
      modelsFiltered = modelsFiltered.filter(e => e.name.toLowerCase().indexOf(selectedFilter.word.toLowerCase()) > -1)
    }
    if (selectedFilter.collection !== "") {
      modelsFiltered = modelsFiltered.filter(e => e.categories.filter(c => c.collectionId === selectedFilter.collection).length > 0);
    }
    if (selectedFilter.category !== "") {
      modelsFiltered = modelsFiltered.filter(e => e.categories.filter(c => c.categoryId === selectedFilter.category).length > 0);
    }
    return modelsFiltered;
  }

  const selectedModels = filterModels();

  // Component Did Mount Hook
  useEffect(() => {
    if (!dataLoaded.current) {
      dispatch(loadCollectionsAction());
      dispatch(loadModelsAction());
      dispatch(loadMaterialsAction());
      dataLoaded.current = true;
    }
  }, [dispatch]);

  const handleFilter = (word?: string, collection?: string, category?: string) => {
    setSelectedFilter({
      word: word ? word : "",
      collection: collection ? collection : "",
      category: category ? category : ""
    })
  }

  const handleCreateModel = async () => {
    setCreatingModel(true);
    await dispatch(createModelAction("Nuevo Modelo")).unwrap();
    setCreatingModel(false);
  }

  const handleModelSelected = (modelId: string) => {
    setSelectedModelId(modelId);
  }

  return (<>
    {loadingStatus && <HabitatLoader />}
    {!loadingStatus && selectedModelId === "" && <>
      <Container>
        <Row>
          <Col xs={6}><h1>Modelos</h1></Col>
          <Col className='mt-2' xs={6}><button disabled={creatingModel} onClick={handleCreateModel} className="btn btn-outline-success float-right">Añadir Modelo <Icon.PlusCircle /></button></Col>
        </Row>
        <ModelSearchBar handleFilter={handleFilter} />
      </Container>
      <Container>
        <ModelGrid models={selectedModels} onModelSelected={handleModelSelected} />
      </Container>
    </>}
    {!loadingStatus && !!selectedModel && <ModelDetails onClickReturn={() => handleModelSelected("")} model={selectedModel} />}
  </>);
}

export default Models;
