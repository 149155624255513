import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EditMaterialRequest,
  MaterialInfo,
  TextureInfo,
} from "../../../services/materials.service";
import MaterialsService from "../../../services/materials.service";
import { AppThunk, RootState } from "../../store";
import { undo } from "redux-undo-action";
import {
  selectModelsUsingMaterial,
  updatedModelMaterials,
} from "../models/modelsSlice";

export interface MaterialsState {
  materials: MaterialInfo[];
  textures: TextureInfo[];
  status: "idle" | "loading" | "failed" | "loaded";
}

const initialState: MaterialsState = {
  materials: [],
  textures: [],
  status: "idle",
};

export const fetchMaterialsAction = createAsyncThunk(
  "materials/fetchMaterials",
  async () => {
    return await MaterialsService.fetchMaterials();
  }
);

export interface UpdateMaterialActionPayload {
  name: string;
  materialId: string;
  color: number[];
  roughnessFactor: number;
  metallicFactor: number;
}

export const updateMaterialAction =
  (update: UpdateMaterialActionPayload): AppThunk =>
  async (dispatch, getState) => {
    const optimisticAction = updateMaterial(update);
    dispatch(optimisticAction);
    try {
      await MaterialsService.editMaterial(update.materialId, {
        name: update.name,
        metallicFactor: update.metallicFactor,
        roughnessFactor: update.roughnessFactor,
        baseColor: update.color,
      } as EditMaterialRequest);
      const modelsUsingMaterial = selectModelsUsingMaterial(update.materialId)(
        getState()
      );
      for (const model of modelsUsingMaterial) {
        dispatch(updatedModelMaterials(model.id));
      }
    } catch (error) {
      console.log("Error updating material. Undoing");
      dispatch(undo(optimisticAction));
    }
  };

export const deleteMaterialAction =
  (materialId: string): AppThunk =>
  async (dispatch, getState) => {
    const optimisticAction = deleteMaterial(materialId);
    dispatch(optimisticAction);
    try {
      await MaterialsService.deleteMaterial(materialId);
    } catch (error) {
      console.log("Error deleting material. Undoing");
      dispatch(undo(optimisticAction));
    }
  };

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    updateMaterial: (
      state,
      action: PayloadAction<UpdateMaterialActionPayload>
    ) => {
      const material = state.materials.find(
        (e) => e.id === action.payload.materialId
      )!;
      material.name = action.payload.name;
      material.baseColor = action.payload.color;
      material.metallicFactor = action.payload.metallicFactor;
      material.roughnessFactor = action.payload.roughnessFactor;
    },
    addMaterial: (state, action: PayloadAction<MaterialInfo>) => {
      state.materials.push(action.payload);
    },
    deleteMaterial: (state, action: PayloadAction<string>) => {
      state.materials = state.materials.filter((e) => e.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMaterialsAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMaterialsAction.fulfilled, (state, action) => {
        state.status = "loaded";
        state.materials = action.payload.materials;
        state.textures = action.payload.textures;
      })
      .addCase(fetchMaterialsAction.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectMaterials = (state: RootState) => state.materials.materials;
export const selectTextures = (state: RootState) => state.materials.textures;
export const selectMaterialsStatus = (state: RootState) => state.materials.status;
export const { updateMaterial, addMaterial, deleteMaterial } = materialsSlice.actions;

export const loadMaterialsAction = (): AppThunk => (dispatch, getState) => {
  const status = selectMaterialsStatus(getState());
  if (status === "idle") {
    dispatch(fetchMaterialsAction());
  }
};

export default materialsSlice.reducer;
