import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import CollectionService, { AddCategoryRequest, AddCollectionRequest, UpdateCategoryRequest, UpdateCollectionRequest } from "../../../services/collection.service";
import { AppThunk, RootState } from "../../store";
import { undo } from 'redux-undo-action';

export interface CollectionInfo {
  name: string;
  description?: string;
  id: string;
  visibleApp: boolean;
  image?: string;
}

export interface CategoryInfo {
  name: string;
  id: string;
  collectionId: string;
  image?: string;
}

export interface CollectionsState {
  collections: CollectionInfo[];
  categories: CategoryInfo[];
  status: "idle" | "loading" | "failed" | "loaded";
}

const initialState: CollectionsState = {
  collections: [],
  categories: [],
  status: "idle",
};

const fetchCollectionsAction = createAsyncThunk(
  "collections/fetchCollections",
  async () => {
    return await CollectionService.fetchCollections();
  }
);

export const createCategoryAction = createAsyncThunk(
  "collections/addCategory",
  async (req: AddCategoryRequest) => {
    return await CollectionService.addCategory(req);
  }
);

export const createCollectionAction = createAsyncThunk(
  "collections/addCollection",
  async (req: AddCollectionRequest) => {
    return await CollectionService.addCollection(req);
  }
);

export interface UpdateCategoryActionPayload {
  name: string;
  collection_id: string;
  category_id: string;
}

export const updateCategoryAction = (update: UpdateCategoryActionPayload): AppThunk => async dispatch => {
  const optimisticAction = updateCollectionCategory(update);
  dispatch(optimisticAction);
  try {
    await CollectionService.editCategory(update.collection_id, update.category_id, {name: update.name} as UpdateCategoryRequest);
  } catch (error) {
    console.log("Error updating category name. Undoing");
    dispatch(undo(optimisticAction));
  }
};

export interface UpdateCollectionActionPayload {
  collectionId: string;
  name: string;
  description: string;
  visibleApp: boolean;
}

export const updateCollectionAction = (update: UpdateCollectionActionPayload): AppThunk => async dispatch => {
  const optimisticAction = updateCollection(update);
  dispatch(optimisticAction);
  try {
    await CollectionService.editCollection(update.collectionId, {name: update.name, description: update.description, visibleApp: update.visibleApp} as UpdateCollectionRequest);
  } catch (error) {
    console.log("Error updating collection. Undoing");
    dispatch(undo(optimisticAction));
  }
};

export const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {
    updateCollection: (state, action: PayloadAction<UpdateCollectionActionPayload>) => {
      const collection = state.collections.find(e => e.id === action.payload.collectionId)!;
      collection.name = action.payload.name;
      collection.description = action.payload.description;
      collection.visibleApp = action.payload.visibleApp;
    },
    updateCollectionImage: (state, action: PayloadAction<CollectionInfo>) => {
      const collection = state.collections.find(e => e.id === action.payload.id)!;
      collection.image = action.payload.image;
    },
    updateCollectionCategory: (state, action: PayloadAction<UpdateCategoryActionPayload>) => {
      const category = state.categories.find(e => e.id === action.payload.category_id)!;
      category.name = action.payload.name;
    },
    updateCollectionCategoryImage: (state, action: PayloadAction<CategoryInfo>) => {
      const category = state.categories.find(e => e.id === action.payload.id)!;
      category.image = action.payload.image;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCategoryAction.fulfilled, (state, action) => {
        state.categories.push({
          name: action.payload.name,
          id: action.payload.id,
          collectionId: action.payload.collectionId,
        } as CategoryInfo)
      })
      .addCase(createCollectionAction.fulfilled, (state, action) => {
        state.collections.push({
          id: action.payload.id,
          name: action.payload.name,
          visibleApp: action.payload.visibleApp,
        } as CollectionInfo)
      })
      .addCase(fetchCollectionsAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCollectionsAction.fulfilled, (state, action) => {
        state.status = "loaded";
        state.categories = action.payload.categories.map((c) => {
          return {
            name: c.name,
            id: c.id,
            collectionId: c.collectionId,
            image: c.image,
          } as CategoryInfo;
        });
        state.collections = action.payload.collections.map((c) => {
            return {
              name: c.name,
              id: c.id,
              visibleApp: c.visibleApp,
              description: c.description,
              image: c.image
            } as CollectionInfo;
          });
      })
      .addCase(fetchCollectionsAction.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectCategories = (state: RootState) => state.collections.categories;
export const selectCollections = (state: RootState) => state.collections.collections;
export const selectCollectionsStatus = (state: RootState) => state.collections.status;
export const { updateCollectionCategory, updateCollectionCategoryImage, updateCollection, updateCollectionImage } = collectionsSlice.actions;

export const loadCollectionsAction = (): AppThunk => (dispatch, getState) => {
  const status = selectCollectionsStatus(getState());
  if(status === "idle") {
      dispatch(fetchCollectionsAction());
  }
}

export default collectionsSlice.reducer;
