import axios from "axios";
import { ModelInfo } from "../app/features/models/modelsSlice";
import { authHeader } from "./auth.service";
import { BACKEND_URL } from "./backend";

export interface MaterialInfo {
  id: string;
  name: string;
  metallicFactor?: number;
  roughnessFactor?: number;
  baseColor?: number[];
  baseTexture?: string;
}

export interface TextureInfo {
  id: string;
  name: string;
  url: string;
}

export interface FetchMaterialsResponse {
  materials: MaterialInfo[];
  textures: TextureInfo[];
}

export interface EditMaterialRequest {
  name: string;
  color?: number[];
  metallicFactor: number;
  roughnessFactor: number;
}

const API_URL = BACKEND_URL + "materials/";

class MaterialsService {
  async fetchMaterials(): Promise<FetchMaterialsResponse> {
    return (await axios.get(API_URL, { headers: await authHeader() }))
      .data as FetchMaterialsResponse;
  }
  async editMaterial(materialId: string, req: EditMaterialRequest) {
    return await axios.post(API_URL + materialId + "/edit", req, {
      headers: await authHeader(),
    });
  }
  async cloneMaterial(materialId: string): Promise<MaterialInfo> {
    return (
      await axios.get(API_URL + materialId + "/clone", { headers: await authHeader() })
    ).data as MaterialInfo;
  }
  async deleteMaterial(materialId: string) {
    return await axios.delete(API_URL + materialId, {
      headers: await authHeader(),
    });
  }
}

export default new MaterialsService();
