import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectTextures } from "../../app/features/materials/materialsSlice";
import { MaterialInfo } from "../../services/materials.service";
import { getMaterialStyle } from "../../services/utils";

export interface MaterialDisplayProps {
    material: MaterialInfo
    onSelectMaterial?: (idMaterial: string) => void;
    selected?: boolean;
}

const MaterialDisplay: React.FC<MaterialDisplayProps> = (props) => {
    const textures = useSelector(selectTextures);

    let materialStyle: React.CSSProperties = {minHeight: "180px"};
    materialStyle = getMaterialStyle(props.material, textures, materialStyle);
    
    return (
        <Container className="card p-3 mb-3 mt-3" style={{cursor: props.onSelectMaterial ? "pointer" : "auto", backgroundColor: props.selected ? "#ffc13fb3" : "transparent"}} onClick={() => props.onSelectMaterial!(props.material.id)}>
            <div className="card-img-top" style={materialStyle}></div>
            <div className="card-title h5 mt-3" style={{textAlign: "center"}}>{props.material.name}</div>
        </Container>
    )
}

export default MaterialDisplay;
