import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "firebase/auth";
import authService from "../../../services/auth.service";
import { AppThunk, RootState } from "../../store";

export interface AuthState {
  loaded: boolean;
  subscribed: boolean;
  currentUser: string | null;
}

const initialState: AuthState = {
  loaded: false,
  subscribed: false,
  currentUser: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        subscribeAuth: (state) => {
            state.subscribed = true;
        },
        authLoaded: (state, action: PayloadAction<string | null>) => {
            state.loaded = true;
            state.currentUser = action.payload;
        }
    }
})

export const selectSubscribed = (state: RootState) => state.auth.subscribed;
export const selectLoaded = (state: RootState) => state.auth.loaded;
export const selectUser = (state: RootState) => state.auth.currentUser;
export const { subscribeAuth, authLoaded } = authSlice.actions;


export const loadAuth = (): AppThunk => (dispatch, getState) => {
    const subscribed = selectSubscribed(getState());
    if(!subscribed) {
        dispatch(subscribeAuth());
        authService.subscribeAuth(dispatch);
    }
}

export default authSlice.reducer;