import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

function NavbarMenu() {
    return (
        <Navbar bg="light" expand="lg" className="border-bottom pt-3 mb-4 pb-3 pl-5 pr-5">
            <Container>
                <Navbar.Brand href="/"><b> </b>Habitat RA Dashboard</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav-menu" />
                <Navbar.Collapse id="navbar-nav-menu" className="justify-content-end">
                    <Nav className="d-flex">
                        <Nav.Item>
                            <Link className='nav-link' to="/models">Modelos</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className='nav-link' to="/collections">Colecciones</Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link className='nav-link' to="/materials">Materiales</Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarMenu;