import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { undo } from "redux-undo-action";
import ModelsService, {
  EditModelComponentRequest,
  EditModelRequest,
} from "../../../services/models.service";
import { AppThunk, RootState } from "../../store";
import { CollectionInfo } from "../collections/collectionsSlice";
import { v4 as uuidv4 } from "uuid";

export interface ModelCategory {
  categoryId: string;
  collectionId: string;
}

export interface ModelMaterialInfo {
  name: string;
  default: number;
  variations: string[];
}

export interface ModelInfo {
  materials: ModelMaterialInfo[];
  id: string;
  name: string;
  visibleApp: boolean;
  lastUpload?: string;
  description?: string;
  categories: ModelCategory[];
  image?: string;
  link?: string;
}

export interface ModelsState {
  status: "idle" | "loading" | "failed" | "loaded";
  list: ModelInfo[];
}

const initialState: ModelsState = {
  status: "idle",
  list: [],
};

const fetchModelsAction = createAsyncThunk("models/fetchModels", async () => {
  return await ModelsService.fetchModels();
});

export const createModelAction = createAsyncThunk(
  "models/create",
  async (name: string) => {
    return await ModelsService.addModel(name);
  }
);

export interface UpdateModelActionPayload {
  id: string;
  name: string;
  description: string;
  visibleApp: boolean;
  link: string;
}

export const updateModelAction =
  (update: UpdateModelActionPayload): AppThunk =>
  async (dispatch) => {
    const optimisticAction = updateModel(update);
    dispatch(optimisticAction);
    try {
      await ModelsService.editModel(update.id, {
        name: update.name,
        description: update.description,
        visibleApp: update.visibleApp,
        link: update.link
      } as EditModelRequest);
    } catch (error) {
      console.log("Error updating model. Undoing");
      dispatch(undo(optimisticAction));
    }
  };

export interface UpdateModelComponentActionPayload {
  name?: string;
  variations?: string[];
  modelId: string;
  materialIndex: number;
}

export const updateModelComponentAction =
  (update: UpdateModelComponentActionPayload): AppThunk =>
  async (dispatch) => {
    const optimisticAction = updateModelComponent(update);
    dispatch(optimisticAction);
    try {
      await ModelsService.editModelComponent(update.modelId, {
        name: update.name,
        variations: update.variations,
        index: update.materialIndex,
      } as EditModelComponentRequest);
      dispatch(updatedModelMaterials(update.modelId));
    } catch (error) {
      console.log("Error updating model component. Undoing");
      dispatch(undo(optimisticAction));
    }
  };

export interface AddModelCategoryActionPayload {
  modelId: string;
  collectionId: string;
  categoryId: string;
}

export const addModelCategoryAction =
  (newCategory: AddModelCategoryActionPayload): AppThunk =>
  async (dispatch) => {
    const optimisticAction = addCategory(newCategory);
    dispatch(optimisticAction);
    try {
      await ModelsService.addCategory(
        newCategory.modelId,
        newCategory.collectionId,
        newCategory.categoryId
      );
    } catch (error) {
      console.log("Error adding category. Undoing");
      dispatch(undo(optimisticAction));
    }
  };

export const deleteModelAction = (modelId: string): AppThunk => async (dispatch) => {
  const optimisticAction = deleteModel(modelId);
  dispatch(optimisticAction);
  try {
    await ModelsService.deleteModel(modelId);
  } catch (error) {
    console.log("Error deleting model. Undoing");
    dispatch(undo(optimisticAction));
  }
}

export const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {
    updatedModelMaterials: (state, action: PayloadAction<string>) => {
      const model = state.list.find((e) => e.id === action.payload)!;
      model.lastUpload = uuidv4();
    },
    updateModel: (state, action: PayloadAction<UpdateModelActionPayload>) => {
      const model = state.list.find((e) => e.id === action.payload.id)!;
      model.name = action.payload.name;
      model.description = action.payload.description;
      model.visibleApp = action.payload.visibleApp;
      model.link = action.payload.link;
    },
    updateModelComponent: (
      state,
      action: PayloadAction<UpdateModelComponentActionPayload>
    ) => {
      const model = state.list.find((e) => e.id === action.payload.modelId)!;
      if (action.payload.name) {
        model.materials[action.payload.materialIndex].name = action.payload.name;
      }
      if (action.payload.variations) {
        model.materials[action.payload.materialIndex].variations = action.payload.variations;
      }
    },
    updateModelImage: (state, action: PayloadAction<ModelInfo>) => {
      const model = state.list.find((e) => e.id === action.payload.id)!;
      model.image = action.payload.image;
    },
    deleteModel: (state, action: PayloadAction<string>) => {
      state.list = state.list.filter(e => e.id !== action.payload);
    },
    updateModelGltf: (state, action: PayloadAction<ModelInfo>) => {
      const model = state.list.find((e) => e.id === action.payload.id)!;
      model.lastUpload = action.payload.lastUpload;
      model.materials = action.payload.materials;
    },
    addCategory: (
      state,
      action: PayloadAction<AddModelCategoryActionPayload>
    ) => {
      const model = state.list.find((e) => e.id === action.payload.modelId)!;
      model.categories.push({
        categoryId: action.payload.categoryId,
        collectionId: action.payload.collectionId,
      } as ModelCategory);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createModelAction.fulfilled, (state, action) => {
        state.list = [
          {
            id: action.payload.id,
            name: action.payload.name,
            visibleApp: action.payload.visibleApp,
            image: action.payload.image,
            lastUpload: action.payload.lastUpload,
            description: action.payload.description,
            categories: action.payload.categories,
            materials: action.payload.materials,
            link: action.payload.link
          } as ModelInfo,
        ].concat(state.list);
      })
      .addCase(fetchModelsAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchModelsAction.fulfilled, (state, action) => {
        state.list = action.payload.map((m) => {
          return {
            id: m.id,
            name: m.name,
            image: m.image,
            description: m.description,
            lastUpload: m.lastUpload,
            visibleApp: m.visibleApp,
            categories: m.categories,
            materials: m.materials,
            link: m.link
          } as ModelInfo;
        });
        state.status = "loaded";
      })
      .addCase(fetchModelsAction.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const selectModelsStatus = (state: RootState) => state.models.status;
export const selectModels = (state: RootState) => state.models.list;
export const selectModelsUsingMaterial =
  (selectedMaterial: string) => (state: RootState) =>
    selectedMaterial !== ""
      ? state.models.list.filter(
          (m) =>
            m.materials.filter((e) => e.variations.includes(selectedMaterial))
              .length > 0
        )
      : [];
export const {
  updateModel,
  updateModelImage,
  deleteModel,
  updateModelGltf,
  updateModelComponent,
  addCategory,
  updatedModelMaterials,
} = modelsSlice.actions;

export const loadModelsAction = (): AppThunk => (dispatch, getState) => {
  const status = selectModelsStatus(getState());
  if (status === "idle") {
    dispatch(fetchModelsAction());
  }
};

export default modelsSlice.reducer;
