import { Row, Col, Container } from "react-bootstrap"
import { MaterialInfo } from "../../services/materials.service";
import MaterialDisplay from "./MaterialDisplay"
import MaterialRoundDisplay from "./MaterialRoundDisplay";

export interface MaterialsRoundSelectorGridProps {
    materials: MaterialInfo[]
    selected?: string;
    onMaterialSelected: (idMaterial: string) => void;
}

export const MaterialsRoundSelectorGrid: React.FC<MaterialsRoundSelectorGridProps> = (props) => {
    return (
        <Row>
            {props.materials.map((m) => <Col key={m.id} md={4}><MaterialRoundDisplay onSelectMaterial={(idMaterial) => props.onMaterialSelected(idMaterial)} material={m} selected={props.selected ? props.selected === m.id : false}/></Col>)}
        </Row>
    )
}