import axios from "axios";
import { authHeader } from "./auth.service";
import { toDataURL, QRCodeToDataURLOptions } from "qrcode";
import { MODEL_API_URL } from "./models.service";
import { BACKEND_URL } from "./backend";

export interface QRCodeModel {
  id: string;
  model_id_with_variations: string;
}

export const QR_API_URL = BACKEND_URL + "qr/";

class QRService {
  async getModelQRCodes(modelId: string): Promise<QRCodeModel[]> {
    const url = MODEL_API_URL + modelId + "/qr-codes";
    return (await axios.get(url, { headers: await authHeader() }))
      .data as QRCodeModel[];
  }

  async createModelQRCode(modelId: string): Promise<QRCodeModel> {
    const url = MODEL_API_URL + modelId + "/qr-codes";
    return (await axios.post(
      url,
      { modelIdConfig: modelId },
      { headers: await authHeader() }
    )).data as QRCodeModel;
  }

  getQRCode(value: string): string {
    const options: QRCodeToDataURLOptions = {
      width: 400,
      margin: 1,
    };
    let qrValue: string = "";
    const infoQR = QR_API_URL + value;

    toDataURL(infoQR, options, (err: any, url: string) => {
      if (err) {
        console.error(err);
        return;
      }
      qrValue = url;
    });

    return qrValue;
  }
}

export default new QRService();
