import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { selectMaterials } from "../../app/features/materials/materialsSlice";
import { ModelInfo, updateModelComponentAction, UpdateModelComponentActionPayload } from "../../app/features/models/modelsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { EditableText } from "../common/EditableText";
import { MaterialsGrid } from "../materials/MaterialsGrid"
import * as Icon from 'react-bootstrap-icons';
import MaterialSearchBar from "../materials/MaterialSearchBar";

interface ModelMaterialsDetailsProps {
    model: ModelInfo;
    onMaterialSelected?: (index: number, materialId: string) => void
}

export const ModelMaterialsDetails: React.FC<ModelMaterialsDetailsProps> = (props) => {
    const materials = useAppSelector(selectMaterials);
    const dispatch = useAppDispatch();
    const [inMaterialSelectionMode, setInMaterialSelectionMode] = useState(-1);
    const [materialsSelected, setMaterialsSelected] = useState([] as string[]);
    const [previewMaterials, setPreviewMaterials] = useState(props.model.materials.map(mts => mts.variations[mts.default]));
    const [selectedFilter, setSelectedFilter] = useState("");
    const navigate = useNavigate();

    const filterMaterials = () => {
        let materialsFiltered = materials;
        if (selectedFilter !== "") {
            materialsFiltered = materialsFiltered.filter(e => e.name.toLowerCase().indexOf(selectedFilter.toLowerCase()) > -1)
        }
        return materialsFiltered;
    }

    const filteredMaterials = filterMaterials();

    const handleComponentNameChange = (index: number, name: string) => {
        dispatch(updateModelComponentAction({
            name: name,
            modelId: props.model.id,
            materialIndex: index
        } as UpdateModelComponentActionPayload));
    }

    useEffect(() => {
        if (inMaterialSelectionMode !== -1) {
            setMaterialsSelected(props.model.materials[inMaterialSelectionMode].variations);
        } else {
            setMaterialsSelected([]);
        }
    }, [inMaterialSelectionMode]);

    const handleMaterialVariationSelected = (idMaterial: string) => {
        if (materialsSelected.includes(idMaterial)) {
            if (materialsSelected.length !== 1) {
                setMaterialsSelected(materialsSelected.filter(e => e !== idMaterial));
            }
        } else {
            setMaterialsSelected(materialsSelected.concat(idMaterial));
        }
    }

    const handleFinishSelectingVariations = () => {
        dispatch(updateModelComponentAction(
            {
                materialIndex: inMaterialSelectionMode,
                modelId: props.model.id,
                variations: materialsSelected
            } as UpdateModelComponentActionPayload
        ))
        setInMaterialSelectionMode(-1);
    }

    const openMaterialDetails = (materialId: string) => {
        navigate("/materials/" + materialId);
    }

/*     const handleMaterialSelected = (componentIndex: number, materialId: string) => {
        const newSelected = [...previewMaterials];
        newSelected[componentIndex] = materialId;
        setPreviewMaterials(newSelected);
        
        
    } */

    return (
        <>
            {inMaterialSelectionMode === -1 && <Container>
                {props.model.materials.map((m, i) =>
                    <div key={i}>
                        <Row className="border-bottom mb-2 pb-2">
                            <Col md={9}>
                                <EditableText text={m.name} onSave={(name: string) => handleComponentNameChange(i, name)} />
                            </Col>
                            <Col md={3}>
                                <button className="btn btn-outline-success float-right" onClick={() => setInMaterialSelectionMode(i)}> Añadir Variaciones <Icon.PlusCircle /></button>
                            </Col>
                        </Row>
                        <MaterialsGrid selected={m.variations.length > 1 ? [previewMaterials[i]] : []} materials={m.variations.map(mat => materials.find(e => e.id === mat)!)} onMaterialSelected={(idMaterial: string) => openMaterialDetails(idMaterial)} />
                    </div>
                )}
            </Container>}
            {inMaterialSelectionMode !== -1 && <Container>
                <Row className="border-top pt-4 mt-3 mb-3 pb-3">
                    <Col md={10}>
                        <h2>Variaciones de {props.model.materials[inMaterialSelectionMode].name}:</h2>
                    </Col>
                    <Col md={2}>
                        <Row>
                            <button type="button" className="btn btn-outline-success float-right" onClick={handleFinishSelectingVariations}><Icon.ArrowLeftCircle /> Volver</button>
                        </Row>
                    </Col>
                </Row>
                <MaterialSearchBar handleFilter={setSelectedFilter} />
                <MaterialsGrid selected={materialsSelected} materials={filteredMaterials} onMaterialSelected={handleMaterialVariationSelected} />
            </Container>}
        </>
    )
}