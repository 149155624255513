import { Row, Col, Container } from "react-bootstrap"
import { MaterialInfo } from "../../services/materials.service";
import MaterialDisplay from "./MaterialDisplay"

export interface MaterialsGridProps {
    materials: MaterialInfo[]
    selected?: string[]
    onMaterialSelected?: (idMaterial: string) => void;
}

export const MaterialsGrid: React.FC<MaterialsGridProps> = (props) => {
    return (
        <Row>
            {props.materials.map((m) => <Col key={m.id} md={3}><MaterialDisplay selected={props.selected?.includes(m.id)} onSelectMaterial={props.onMaterialSelected} material={m} /></Col>)}
        </Row>
    )
}