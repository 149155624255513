import { useRef, useEffect } from 'react';
import { Button, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { loadCollectionsAction } from '../app/features/collections/collectionsSlice';
import { loadMaterialsAction } from '../app/features/materials/materialsSlice';
import { loadModelsAction } from '../app/features/models/modelsSlice';
import { useAppDispatch } from '../app/hooks';

function Page1() {
    const dataLoaded = useRef(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!dataLoaded.current) {
            dispatch(loadCollectionsAction());
            dispatch(loadModelsAction());
            dispatch(loadMaterialsAction());
            dataLoaded.current = true;
        }
    }, [dispatch]);

    return (
        <Container>
            <Card className="p-md-5 p-1 rounded-3">
                <Card.Body>
                    <Card.Title className="pb-3">Estadísticas de uso:</Card.Title>
                    <Card.Text>
                        <Row>
                            <Col sm={12} md={6} className='pb-3 pb-md-3'>
                                <p>Modelos cargados en la libreria: <b>17/20</b></p>
                                <ProgressBar variant="warning" now={60} />
                            </Col>
                            <Col sm={12} md={6} className='pb-3 pb-md-3'>
                                <p>Descargas mensuales de la libreria: <b>85/100</b></p>
                                <ProgressBar variant="danger" now={80} />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6} className='pb-3 pb-md-3'>
                                <p>Modelos cargados en la libreria: <b>17/20</b></p>
                                <ProgressBar variant="warning" now={60} />
                            </Col>
                            <Col sm={12} md={6} className='pb-3 pb-md-3'>
                                <p>Descargas mensuales de la libreria: <b>85/100</b></p>
                                <ProgressBar variant="danger" now={80} />
                            </Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Page1;
