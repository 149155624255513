import { Container, Row, Col, Card, Form, ListGroup, Tabs, Tab } from "react-bootstrap";
import { addModelCategoryAction, AddModelCategoryActionPayload, deleteModelAction, ModelInfo, updateModelAction, UpdateModelActionPayload, updateModelGltf, updateModelImage } from "../../app/features/models/modelsSlice";
import * as Icon from 'react-bootstrap-icons';
import { useEffect, useState } from "react";
import { selectCategories, selectCollections } from "../../app/features/collections/collectionsSlice";
import { useAppDispatch } from "../../app/hooks";
import ModelsService from "../../services/models.service";
import { HabitatLoader } from "../common/HabitatLoader";
import ModelViewer from "../viewers/ModelViewer";
import { useSelector } from "react-redux";
import CategorySelector from "../collections/CategorySelector";
import { fetchMaterialsAction } from "../../app/features/materials/materialsSlice";
import { ModelMaterialsDetails } from "../models/ModelMaterialsDetails";
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from "react-router-dom";
import { MaterialsSidebarContent } from "../materials/MaterialsSidebarContent";
import ModelQRCodesTab from "./ModelQRCodesTab";

export interface ModelDetailsProps {
    model: ModelInfo;
    onClickReturn: () => void;
}

const ModelDetails: React.FC<ModelDetailsProps> = (props) => {
    const [inEditionMode, setEditionMode] = useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [modelUploadInProgress, setModelUploadInProgress] = useState(false);
    const [openTab, setOpenTab] = useState("detalles");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedVariations, setSelectedVariations] = useState(new Map() as Map<number, string>)
    const [selectedCollection, setSelectedCollection] = useState("");
    const categories = useSelector(selectCategories);
    const collections = useSelector(selectCollections);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: props.model.name,
        description: props.model.description,
        visibleApp: props.model.visibleApp,
        link: props.model.link,
        gltf: "",
        image: ""
    });
    const [formErrors, setErrors] = useState({} as any);
    const setFormField = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value
        });
        if (!!formErrors[field]) {
            setErrors({
                ...formErrors,
                [field]: null
            })
        }
    }

    const validateForm = () => {
        const { name } = formData;
        const newErrors = {} as any;
        if (!name || name === '') newErrors.name = 'Introduce un nombre de modelo válido';
        return newErrors;
    }

    const handleSaveChanges = async (e?: any) => {
        if (!!e) { e.preventDefault(); }
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            if (formData.gltf != "") {
                setModelUploadInProgress(true);
                try {
                    const modelInfo = await ModelsService.uploadModelGltf(props.model.id, formData.gltf as any);
                    dispatch(updateModelGltf(modelInfo));
                    dispatch(fetchMaterialsAction());
                } finally {
                    setModelUploadInProgress(false);
                }
            }
            if (formData.image != "") {
                setUploadInProgress(true);
                try {
                    const modelInfo = await ModelsService.uploadModelImage(props.model.id, formData.image as any);
                    dispatch(updateModelImage(modelInfo));
                } finally {
                    setUploadInProgress(false);
                }
            }
            dispatch(updateModelAction({
                name: formData.name,
                id: props.model.id,
                description: formData.description,
                visibleApp: formData.visibleApp,
                link: formData.link
            } as UpdateModelActionPayload));
            setFormField("image", "");
            setFormField("gltf", "");
            setEditionMode(false);
        }
    }

    const handleCancelChanges = () => {
        setFormData({
            name: props.model.name,
            description: props.model.description,
            visibleApp: props.model.visibleApp,
            image: "",
            gltf: "",
            link: props.model.link
        });
        setErrors({});
        setEditionMode(false);
    }

    const handleAddCategory = () => {
        dispatch(addModelCategoryAction({
            modelId: props.model.id,
            categoryId: selectedCategory,
            collectionId: selectedCollection
        } as AddModelCategoryActionPayload));
        setSelectedCategory("");
        setSelectedCollection("");
    }

    const handleMaterialSelected = (index: number, materialId: string) => {
        const newSelected = new Map(selectedVariations.entries());
        newSelected.set(index, materialId);
        setSelectedVariations(newSelected);
    }

    const deleteModel = () => {
        const confirmed = window.confirm("¿Deseas borrar el modelo? Esta acción no se puede deshacer.");
        if (confirmed) {
            dispatch(deleteModelAction(props.model.id));
            props.onClickReturn();
        }
    }

    return (
        <Container>
            <Row>
                <Col xs={10}><h1>{formData.name}</h1></Col>
                <Col className='mt-2' xs={2}>
                    <Row>
                        <button type="button" className="btn btn-outline-success float-right" onClick={props.onClickReturn}><Icon.ArrowLeftCircle /> Listado de modelos</button>
                    </Row>
                </Col>
            </Row>
            {modelUploadInProgress &&
                <>
                    <p style={{ textAlign: "center" }}>Subiendo el modelo...</p>
                    <HabitatLoader />
                </>
            }
            {!modelUploadInProgress &&
                <Tabs className="mb-3" activeKey={openTab} onSelect={(k) => setOpenTab(k!)}>
                    {props.model.lastUpload && <Tab key={"visor"} eventKey={"visor"} title="Visor 3D">
                        <div className="position-relative">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <ModelViewer model={props.model} variations={selectedVariations} />
                                    </div>
                                    <div className="position-absolute top-0 end-0" style={{ "width": "25%", "marginTop": "10%" }}>
                                        <MaterialsSidebarContent model={props.model} onMaterialSelected={handleMaterialSelected} selected={selectedVariations}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>}
                    <Tab key={"detalles"} eventKey={"detalles"} title="Detalles">
                        <Row className="mt-5">
                            <Col md={10} style={{ textAlign: 'justify' }}>
                                <Form onSubmit={handleSaveChanges}>
                                    <Row className="mb-3">
                                        <Form.Label column sm="2"><b>Nombre: </b></Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type="text"
                                                placeholder={formData.name !== '' ? formData.name : 'Nombre del modelo'}
                                                value={formData.name}
                                                isInvalid={!!formErrors.name}
                                                onChange={(e) => setFormField('name', e.target.value)}
                                                readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                            <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    {(!!formData.link || inEditionMode) && <Row className="mb-3">
                                        <Form.Label column sm="2"><b>Link: </b></Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type="text"
                                                placeholder={formData.link !== '' ? formData.link : 'Link info modelo'}
                                                value={formData.link}
                                                isInvalid={!!formErrors.link}
                                                onChange={(e) => setFormField('link', e.target.value)}
                                                readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                            <Form.Control.Feedback type='invalid'>{formErrors.link}</Form.Control.Feedback>
                                        </Col>
                                    </Row>}
                                    <Row className="mb-3">
                                        <Form.Label column sm="2"><b>Visible App: </b></Form.Label>
                                        <Col sm={10} className="pt-2 pb-2">
                                            <Form.Check
                                                type="switch"
                                                id="visibilidad-switch"
                                                disabled={!inEditionMode}
                                                defaultChecked={formData.visibleApp}
                                                value={formData.visibleApp ? 'off' : 'on'}
                                                onChange={(e) => setFormField('visibleApp', e.target.value === 'on' ? true : false)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Label column sm="2"><b>Categoría: </b></Form.Label>
                                        <Col sm={10} className="pt-2 pb-2">
                                            {inEditionMode && <><Row>
                                                <Col md={10}>
                                                    <CategorySelector onSelect={(collection, category) => {
                                                        setSelectedCategory(category);
                                                        setSelectedCollection(collection);
                                                    }} />
                                                </Col>
                                                <Col md={2}>
                                                    <button className="btn btn-outline-success" disabled={uploadInProgress || selectedCategory == ""} onClick={handleAddCategory}>Añadir</button>
                                                </Col>
                                            </Row><ListGroup variant="flush">
                                                    {props.model.categories.map((c) => <ListGroup.Item key={c.categoryId} action onClick={console.log}>
                                                        <>
                                                            {collections.find(e => e.id === c.collectionId)!.name} - {categories.find(e => e.id === c.categoryId)!.name}
                                                        </>
                                                    </ListGroup.Item>
                                                    )}
                                                </ListGroup></>}
                                        </Col>
                                    </Row>
                                    {(!!props.model.image || inEditionMode) && <Row className="mb-3">
                                        <Form.Label column sm="2"><b>Imagen: </b></Form.Label>
                                        <Col sm={10} className="pt-2 pb-3">
                                            {!inEditionMode && <Card><Card.Img variant="top" src={props.model.image} /></Card>}
                                            {inEditionMode && <Form.Control type="file" onChange={(e) => setFormField('image', (e.target as any).files[0])} readOnly={!inEditionMode} />}
                                        </Col>
                                    </Row>}
                                    {(!!props.model.lastUpload || inEditionMode) && <Row className="mb-3">
                                        {inEditionMode && <>
                                            <Form.Label column sm="2"><b>GLTF: </b></Form.Label>
                                            <Col sm={10} className="pt-2 pb-3">
                                                <Form.Control type="file" onChange={(e) => setFormField('gltf', (e.target as any).files[0])} readOnly={!inEditionMode} />
                                            </Col>
                                        </>}
                                    </Row>}
                                    {(!!formData.description || inEditionMode) && <Row className="mb-3">
                                        <Form.Label column sm="2"><b>Descripción: </b></Form.Label>
                                        <Col sm="10">
                                            <Form.Control as="textarea" rows={3}
                                                placeholder={!!formData.description ? formData.description : 'Descripción del modelo'}
                                                value={formData.description}
                                                isInvalid={!!formErrors.description}
                                                onChange={(e) => setFormField('description', e.target.value)}
                                                readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                            <Form.Control.Feedback type='invalid'>{formErrors.description}</Form.Control.Feedback>
                                        </Col>
                                    </Row>}
                                </Form>
                            </Col>
                            <Col md={2}>
                                {!inEditionMode &&
                                    <>
                                        <Row className="mb-3"><button onClick={() => setEditionMode(true)} className="btn btn-outline-warning float-right">Editar</button></Row>
                                        <Row className="mb-3"><button onClick={deleteModel} className="btn btn-outline-danger float-right">Borrar</button></Row>
                                    </>
                                }
                                {inEditionMode && <>
                                    <Row className="mb-3">
                                        <button className="btn btn-outline-success" disabled={uploadInProgress} onClick={() => handleSaveChanges()}>Guardar</button>
                                    </Row>
                                    <Row>
                                        <button className="btn btn-outline-secondary" disabled={uploadInProgress} onClick={() => handleCancelChanges()}>Cancelar</button>
                                    </Row>
                                </>}
                            </Col>
                        </Row >
                    </Tab>
                    <Tab key={"materiales"} eventKey={"materiales"} title="Materiales">
                        <ModelMaterialsDetails model={props.model} />
                    </Tab>
                    {props.model.lastUpload && <Tab key={"qr-codes"} eventKey={"qr-codes"} title="Códigos QR">
                        <ModelQRCodesTab model={props.model} />
                    </Tab>}
                </Tabs>
            }
        </Container >
    )
}

export default ModelDetails;