import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectTextures } from "../../app/features/materials/materialsSlice";
import { MaterialInfo } from "../../services/materials.service";
import { getMaterialStyle } from "../../services/utils";

export interface MaterialDisplayProps {
    material: MaterialInfo
    onSelectMaterial: (idMaterial: string) => void;
    selected?: boolean;
}

const MaterialRoundDisplay: React.FC<MaterialDisplayProps> = (props) => {
    const textures = useSelector(selectTextures);

    let materialStyle: React.CSSProperties = {minHeight: "50px"};
    materialStyle = getMaterialStyle(props.material, textures, materialStyle);
    
    return (
        <Container className="card p-0 mb-0 mt-2" style={{cursor: "pointer", backgroundColor: props.selected ? "black" : "transparent", border: props.selected ? "2px solid black" : "transparent"}} onClick={() => props.onSelectMaterial(props.material.id)}>
            <div className="card-img-top" style={materialStyle}></div>
        </Container>
    )
}

export default MaterialRoundDisplay;
