import { FirebaseError } from "firebase/app";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import { selectUser } from "../app/features/auth/authSlice";
import { useAppSelector } from "../app/hooks";
import AuthService from "../services/auth.service";

function LoginPage() {
    const [email, setEmail] = useState("");
    const [loginError, setLoginError] = useState("");
    const [password, setPassword] = useState("");
    const user = useAppSelector(selectUser);

    const login = async () => {
        setLoginError("");
        try {
            await AuthService.login(email, password);
        } catch (error) {
            setLoginError("Comprueba las credenciales");
        }
    }

    if (!user) {
        return (
            <Container>
                <Row>
                    <Col md={5} style={{ "margin": "0 auto" }}>
                        <Form>
                            <Form.Group className="mb-3" controlId="formLoginEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formLoginPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>
                        </Form>
                        {loginError != "" && <div className="alert alert-danger" role="alert">*Comprueba las credenciales</div>}
                        <Row className="pt-3 ml-3 mr-3">
                            <button className="btn btn-outline-success" disabled={email === "" || password === ""} onClick={login}>Login</button>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return (
            <Navigate to="/" />
        )
    }

}

export default LoginPage;