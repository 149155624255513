import { useRef, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { selectMaterials, selectTextures, selectMaterialsStatus, loadMaterialsAction } from "../app/features/materials/materialsSlice";
import { loadModelsAction, selectModels, selectModelsUsingMaterial } from "../app/features/models/modelsSlice";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { HabitatLoader } from "../components/common/HabitatLoader";
import MaterialDetails from "../components/materials/MaterialDetails";
import MaterialSearchBar from "../components/materials/MaterialSearchBar";
import { MaterialsGrid } from "../components/materials/MaterialsGrid";
import ModelGrid from "../components/models/ModelGrid";
import { MaterialInfo } from "../services/materials.service";

function MaterialsPage() {
    const dataLoaded = useRef(false);
    const materials = useAppSelector(selectMaterials);
    const textures = useAppSelector(selectTextures);
    const { materialId } = useParams();
    const [selectedMaterial, setSelectedMaterial] = useState(materialId ? materialId : "");
    const loadingStatus = useAppSelector(selectMaterialsStatus);
    const dispatch = useAppDispatch();
    const modelsUsingMaterial = useAppSelector(selectModelsUsingMaterial(selectedMaterial));
    const navigate = useNavigate();

    const [selectedFilter, setSelectedFilter] = useState("");

    const filterMaterials = () => {
        let materialsFiltered = materials;
        if (selectedFilter !== "") {
            materialsFiltered = materialsFiltered.filter(e => e.name.toLowerCase().indexOf(selectedFilter.toLowerCase()) > -1)
        }
        return materialsFiltered;
    }

    const filteredMaterials = filterMaterials();

    // Component Did Mount Hook
    useEffect(() => {
        if (!dataLoaded.current) {
            dispatch(loadMaterialsAction());
            dispatch(loadModelsAction());
            dataLoaded.current = true;
        }
    }, []);

    const openModelDetails = (modelId: string) => {
        navigate("/models/" + modelId);
    }

    const handleMaterialClone = (material: MaterialInfo) => {
        setSelectedMaterial("");
        setTimeout(() => setSelectedMaterial(material.id), 100);
    }

    return (
        <Container>
            {/* Materials Grid */}
            {selectedMaterial === '' &&
                <Container>
                    <Row className="mb-3">
                        <Col md={3}>
                            <h1 className="mt-2">Materiales</h1>
                        </Col>
                        <Col md={6}>
                            <MaterialSearchBar handleFilter={setSelectedFilter} />
                        </Col>
                    </Row>
                </Container>
            }
            {loadingStatus === 'loading' && <HabitatLoader />}
            {loadingStatus === 'loaded' && selectedMaterial === '' && <MaterialsGrid materials={filteredMaterials} onMaterialSelected={setSelectedMaterial} />}
            {loadingStatus === 'loaded' && selectedMaterial !== '' && <MaterialDetails materialId={selectedMaterial} onClickReturn={() => setSelectedMaterial("")} onClickClone={handleMaterialClone} />}
            {loadingStatus === 'loaded' && selectedMaterial !== '' && <Row>
                <h3 className="border-bottom border-top pt-4 mt-3 mb-3 pb-3">Modelos utilizando el material</h3>
                <ModelGrid models={modelsUsingMaterial} onModelSelected={openModelDetails} />
            </Row>}
        </Container>
    )
}


export default MaterialsPage;