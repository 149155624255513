import { Card, Col, Row } from 'react-bootstrap';
import { ModelInfo } from '../../app/features/models/modelsSlice';

interface ModelGridProps {
    models: ModelInfo[];
    onModelSelected: (modelId: string) => void;
}

const ModelGrid: React.FC<ModelGridProps> = (props) => {
    return (
    <>
        {props.models.length === 0 && <p>No se han encontrado modelos.</p>}
        {props.models.length > 0 && <Row xs={2} md={4} className="g-4">
            {props.models.map((model) => (
                <Col key={model.id}>
                    <Card onClick={() => props.onModelSelected(model.id)} style={{ cursor: "pointer" }}>
                        {model.image && <Card.Img variant="top" src={model.image} />}
                        <Card.Body>
                            <Card.Title style={{textAlign: "center"}}>{model.name}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>}
    </>);
}

export default ModelGrid;
