import { Col, Form, Row } from "react-bootstrap";
import { addMaterial, deleteMaterialAction, selectMaterials, updateMaterialAction, UpdateMaterialActionPayload } from "../../app/features/materials/materialsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import * as Icon from 'react-bootstrap-icons';
import MaterialDisplay from "./MaterialDisplay";
import { useEffect, useState } from "react";
import rgbHex from 'rgb-hex';
import hexRgb from "hex-rgb";
import MaterialsService, { MaterialInfo } from "../../services/materials.service";
import { useNavigate } from "react-router-dom";
import { selectModelsUsingMaterial } from "../../app/features/models/modelsSlice";
import MaterialViewer from "../viewers/MaterialViewer";
import { hexColorToArray } from "../../services/utils";


export interface MaterialDetailsProps {
    materialId: string;
    onClickReturn: () => void;
    onClickClone: (material: MaterialInfo) => void;
}

const MaterialDetails: React.FC<MaterialDetailsProps> = (props) => {
    const materials = useAppSelector(selectMaterials);
    const material = materials.find(e => e.id === props.materialId)!;
    const [inEditionMode, setEditionMode] = useState(false);
    const [cloneInProgress, setCloneInProgress] = useState(false);
    const [materialPreview, setMaterialPreview] = useState(material);
    const dispatch = useAppDispatch();
    const [formData, setFormData] = useState({
        name: material.name,
        color: material.baseColor ? "#" + rgbHex(material.baseColor[0] * 255, material.baseColor[1] * 255, material.baseColor[2] * 255) : "",
        metallicFactor: material.metallicFactor,
        roughnessFactor: material.roughnessFactor
    });
    const [formErrors, setErrors] = useState({} as any);
    const modelsUsingMaterial = useAppSelector(selectModelsUsingMaterial(props.materialId));
    const setFormField = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value
        });
        if (!!formErrors[field]) {
            setErrors({
                ...formErrors,
                [field]: null
            })
        }
    }

    const onPreviewMaterial = () => {
        const newMaterialPreview = {...materialPreview};
        newMaterialPreview.baseColor = hexColorToArray(formData.color);
        newMaterialPreview.metallicFactor = formData.metallicFactor;
        newMaterialPreview.roughnessFactor = formData.roughnessFactor;
        setMaterialPreview(newMaterialPreview);
    }

    const validateForm = () => {
        const { name } = formData;
        const newErrors = {} as any;
        if (!name || name === '') newErrors.name = 'Introduce un nombre de material válido';
        return newErrors;
    }

    const handleSaveChanges = async (e?: any) => {
        if (!!e) { e.preventDefault(); }
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            let color = null;
            if (formData.color != "") {
                color = hexColorToArray(formData.color);
            }
            dispatch(updateMaterialAction({
                name: formData.name,
                metallicFactor: formData.metallicFactor,
                roughnessFactor: formData.roughnessFactor,
                color: color,
                materialId: props.materialId
            } as UpdateMaterialActionPayload));
            setEditionMode(false);
        }
    }

    const handleCancelChanges = () => {
        setFormData({
            name: material.name,
            color: material.baseColor ? "#" + rgbHex(material.baseColor[0] * 255, material.baseColor[1] * 255, material.baseColor[2] * 255) : "",
            roughnessFactor: material.roughnessFactor,
            metallicFactor: material.metallicFactor
        });
        setErrors({});
        setEditionMode(false);
    }

    const cloneMaterial = async () => {
        setCloneInProgress(true);
        try {
            const newMaterial = await MaterialsService.cloneMaterial(props.materialId);
            dispatch(addMaterial(newMaterial));
            props.onClickClone(newMaterial);
        } finally {
            setCloneInProgress(false);
        }
    }

    const deleteMaterial = () => {
        const confirmed = window.confirm("¿Deseas borrar el material? Esta acción no se puede deshacer.");
        if(confirmed) {
            dispatch(deleteMaterialAction(props.materialId));
            props.onClickReturn();
        }
    }

    return (
        <>
            <Row className="border-bottom mb-3 pb-3">
                <Col xs={5}><h1>{formData.name}</h1></Col>
                <Col xs={7}><button onClick={props.onClickReturn} className="btn btn-outline-success float-right"><Icon.ArrowLeftCircle /> Listado de Materiales</button></Col>
            </Row>
            <Row>
                <Col md={3}><MaterialViewer material={materialPreview}/></Col>
                <Col md={9}>
                    <Row className="m-3 p-3">
                        <Col md={10} style={{ textAlign: 'justify' }}>
                            <Form onSubmit={handleSaveChanges}>
                                <Row className="mb-3">
                                    <Form.Label column sm="5"><b>Nombre: </b></Form.Label>
                                    <Col sm="6">
                                        <Form.Control
                                            type="text"
                                            placeholder={formData.name !== '' ? formData.name : 'Nombre del material'}
                                            value={formData.name}
                                            isInvalid={!!formErrors.name}
                                            onChange={(e) => setFormField('name', e.target.value)}
                                            readOnly={!inEditionMode} plaintext={!inEditionMode} />
                                        <Form.Control.Feedback type='invalid'>{formErrors.name}</Form.Control.Feedback>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label column sm="5"><b>Metallic Factor: </b></Form.Label>
                                    {inEditionMode && <Col sm="5" className="mt-2">
                                        <Form.Range value={formData.metallicFactor ? formData.metallicFactor * 100 : 0} onChange={(e) => setFormField('metallicFactor', Number.parseInt(e.target.value) / 100)} />
                                    </Col>}
                                    <Col sm="1" className="mt-2">
                                        {formData.metallicFactor}
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Label column sm="5"><b>Roughness Factor: </b></Form.Label>
                                    {inEditionMode && <Col sm="5" className="mt-2">
                                        <Form.Range value={formData.roughnessFactor ? formData.roughnessFactor * 100 : 0} onChange={(e) => setFormField('roughnessFactor', Number.parseInt(e.target.value) / 100)} />
                                    </Col>}
                                    <Col sm="1" className="mt-2">
                                        {formData.roughnessFactor}
                                    </Col>
                                </Row>
                                {!material.baseTexture && <Row className="mb-3">
                                    <Form.Label column sm="5"><b>Color Base: </b></Form.Label>
                                    <Col sm="2">
                                        <Form.Control
                                            type="color"
                                            value={formData.color}
                                            onChange={(e) => setFormField('color', e.target.value)}
                                            disabled={!inEditionMode}
                                        />
                                    </Col>
                                </Row>}
                            </Form>
                        </Col>
                        <Col md={2}>
                            {!inEditionMode && <button onClick={() => setEditionMode(true)} className="btn btn-outline-warning float-right">Editar</button>}
                            {!inEditionMode && <button onClick={cloneMaterial} disabled={cloneInProgress} className="btn btn-outline-info mt-2 float-right">Clonar</button>}
                            {!inEditionMode && modelsUsingMaterial.length === 0 && <button onClick={deleteMaterial} disabled={cloneInProgress} className="btn btn-outline-danger mt-2 float-right">Borrar</button>}
                            {inEditionMode && <>
                                <Row className="mb-3">
                                    <button className="btn btn-outline-success" onClick={() => handleSaveChanges()}>Guardar</button>
                                </Row>
                                <Row className="mb-3">
                                    <button className="btn btn-outline-secondary" onClick={() => handleCancelChanges()}>Cancelar</button>
                                </Row>
                                <Row>
                                    <button className="btn btn-outline-info" onClick={() => onPreviewMaterial()}>Previsualizar</button>
                                </Row>
                            </>}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {}
        </>
    )
}

export default MaterialDetails;
