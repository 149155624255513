import { Dictionary } from "@reduxjs/toolkit";
import axios from "axios";
import { authHeader } from "./auth.service";
import { BACKEND_URL } from "./backend";

export interface CollectionInfoResponse {
  name: string;
  id: string;
  description?: string;
  visibleApp: boolean;
  image?: string;
}

export interface CategoryInfoResponse {
  name: string;
  id: string;
  collectionId: string;
  image?: string;
}

export interface FetchCollectionsResponse {
  collections: CollectionInfoResponse[];
  categories: CategoryInfoResponse[];
}

export interface AddCollectionRequest {
  name: string;
}

export interface AddCategoryRequest {
  name: string;
  collection_id: string;
}

export interface UpdateCategoryRequest {
  name: string;
}

export interface UpdateCollectionRequest {
  name: string;
  description?: string;
  visibleApp?: boolean;
}

const API_URL = BACKEND_URL + "collections/";

class CollectionService {
  async fetchCollections(): Promise<FetchCollectionsResponse> {
    return (await axios.get(API_URL, { headers: await authHeader() })).data as FetchCollectionsResponse;
  }

  async addCollection(req: AddCollectionRequest) {
    return (
      await axios.post(
        API_URL + "add",
        { name: req.name },
        { headers: await authHeader() }
      )
    ).data as CollectionInfoResponse;
  }

  async editCollection(collectionId: string, req: UpdateCollectionRequest) {
    return (
      await axios.post(
        API_URL + collectionId + "/edit",
        req,
        { headers: await authHeader() }
      )
    )
  }

  async uploadCollectionImage(collectionId: string, f: File) {
    const formData = new FormData();
    formData.append("fle", f, f.name);
    return (
      await axios.post(
        API_URL + collectionId + "/image",
        formData,
        { headers: await authHeader() }
      )
    ).data as CollectionInfoResponse;
  }

  async addCategory(req: AddCategoryRequest) {
    return (
      await axios.post(
        API_URL + req.collection_id + "/category/add",
        { name: req.name },
        { headers: await authHeader() }
      )
    ).data as CategoryInfoResponse;
  }

  async uploadCategoryImage(collectionId: string, categoryId: string, f: File) {
    const formData = new FormData();
    formData.append("fle", f, f.name);
    return (
      await axios.post(
        API_URL + collectionId + "/category/" + categoryId + "/image",
        formData,
        { headers: await authHeader() }
      )
    ).data as CategoryInfoResponse;
  }

  async editCategory(collectionId: string, categoryId: string, req: UpdateCategoryRequest) {
    return (
      await axios.post(
        API_URL + collectionId + "/category/" + categoryId,
        req,
        { headers: await authHeader() }
      )
    )
  }
}

export default new CollectionService();
