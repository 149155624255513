import axios from "axios";
import { ModelInfo } from "../app/features/models/modelsSlice";
import { authHeader } from "./auth.service";
import { BACKEND_URL } from "./backend";
import { MaterialInfo } from "./materials.service";

export interface ModelCategoryInfoResponse {
  categoryId: string;
  collectionId: string;
}

export interface ModelMaterialInfoResponse {
  name: string;
  default: number;
  variations: string[];
}

export interface ModelInfoResponse {
  name: string;
  id: string;
  visibleApp: boolean;
  materials: ModelMaterialInfoResponse[];
  description?: string;
  lastUpload?: string;
  categories: ModelCategoryInfoResponse[];
  image?: string;
  link?: string;
}

export interface EditModelRequest {
  name: string;
  description?: string;
  link?: string;
  visibleApp: boolean;
}

export interface EditModelComponentRequest {
  name?: string;
  index: number;
  variations?: string[];
}

export const MODEL_API_URL = BACKEND_URL + "models/";

class ModelsService {
  async fetchModels(): Promise<ModelInfoResponse[]> {
    return (await axios.get(MODEL_API_URL, { headers: await authHeader() }))
      .data as ModelInfoResponse[];
  }

  getModelWithVariationsUrl(id: string, variations: Map<number, string>) {
    let gltfDir = `${id}`;
    const varList: string[] = [];
    variations.forEach((value, key) => varList.push(`var${key}_${value}`));
    if (varList.length > 0) {
      gltfDir += "__";
      gltfDir += varList.join("__");
    }
    gltfDir += ".gltf";
    return gltfDir;
  }

  getMaterialWithVariationsURL(material: MaterialInfo) {
    let url = "";
    url += material.id;
    if(material.baseColor) {
      url += "__" + `var${"color"}_${JSON.stringify(material.baseColor)}` 
    }
    if(material.metallicFactor) {
      url += "__" + `var${"metallic"}_${material.metallicFactor}` 
    }
    if(material.roughnessFactor) {
      url += "__" + `var${"roughness"}_${material.roughnessFactor}` 
    }
    return `${url}.gltf`;
  }

  getMaterialPreviewURL(material: MaterialInfo): string {
    return (
      MODEL_API_URL + "preview/" + 
      this.getMaterialWithVariationsURL(material)
    );
  }

  getModelGLTFURI(
    modelId: string,
    variations: Map<number, string>,
    version: string
  ) {
    return (
      MODEL_API_URL +
      this.getModelWithVariationsUrl(modelId, variations) +
      "?version=" +
      version
    );
  }

  async addModel(name: string) {
    return (
      await axios.post(
        MODEL_API_URL + "add",
        { name: name },
        { headers: await authHeader() }
      )
    ).data as ModelInfoResponse;
  }

  async deleteModel(modelId: string) {
    return await axios.delete(MODEL_API_URL + modelId, {
      headers: await authHeader(),
    });
  }

  async editModel(modelId: string, req: EditModelRequest) {
    return await axios.post(MODEL_API_URL + modelId + "/edit", req, {
      headers: await authHeader(),
    });
  }

  async editModelComponent(modelId: string, req: EditModelComponentRequest) {
    return await axios.post(MODEL_API_URL + modelId + "/edit-component", req, {
      headers: await authHeader(),
    });
  }

  async addCategory(modelId: string, collectionId: string, categoryId: string) {
    return await axios.post(
      MODEL_API_URL + modelId + "/category",
      { collectionId: collectionId, categoryId: categoryId },
      { headers: await authHeader() }
    );
  }

  async uploadModelImage(modelId: string, f: File) {
    const formData = new FormData();
    formData.append("fle", f, f.name);
    return (
      await axios.post(MODEL_API_URL + modelId + "/image", formData, {
        headers: await authHeader(),
      })
    ).data as ModelInfoResponse;
  }

  async uploadModelGltf(modelId: string, f: File) {
    const formData = new FormData();
    formData.append("fle", f, f.name);
    return (
      await axios.post(MODEL_API_URL + modelId + "/upload", formData, {
        headers: await authHeader(),
      })
    ).data as ModelInfoResponse;
  }
}

export default new ModelsService();
