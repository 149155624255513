import hexRgb from "hex-rgb";
import { MaterialInfo, TextureInfo } from "./materials.service";

export function hexColorToArray(c: string) {
    let color = null;
    color = hexRgb(c, { format: 'array' });
    color[0] = color[0] / 255;
    color[1] = color[1] / 255;
    color[2] = color[2] / 255;
    return color;
}

export function getMaterialStyle(material: MaterialInfo, textures: TextureInfo[], baseStyle?: React.CSSProperties) {
    let materialStyle: React.CSSProperties = {};    
    if(baseStyle != undefined) {
        materialStyle = baseStyle;    
    } 
    if(material.baseColor){
        const color = material.baseColor;
        materialStyle.backgroundColor = `rgba(${color[0]*255}, ${color[1]*255}, ${color[2]*255}, ${color[3]})`;
    }
    if(material.baseTexture){
        const texture = textures.find(e => e.id === material.baseTexture)!;
        materialStyle.backgroundImage = 'url("' + texture.url + '")'
    }
    return materialStyle
}

