import React from 'react';
import { Route, Navigate } from "react-router-dom";
import { selectUser } from '../../app/features/auth/authSlice';
import { useAppSelector } from '../../app/hooks';
import AuthService from "../../services/auth.service";

export type ProtectedRouteProps = {
    outlet: JSX.Element;
};

export default function ProtectedRoute({ outlet }: ProtectedRouteProps) {
    const user = useAppSelector(selectUser);
    if (!!user) {
        return outlet;
    } else {
        return <Navigate to="/login" />;
    }
};