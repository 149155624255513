import { Accordion } from "react-bootstrap"
import { selectMaterials } from "../../app/features/materials/materialsSlice";
import { ModelInfo } from "../../app/features/models/modelsSlice";
import { useAppSelector } from "../../app/hooks";
import { MaterialsRoundSelectorGrid } from "./MaterialsRoundSelectorGrid";


export interface MaterialsSidebarContentProps {
    model: ModelInfo
    selected: Map<number, string>
    onMaterialSelected: (index: number, materialId: string) => void
}


export const MaterialsSidebarContent: React.FC<MaterialsSidebarContentProps> = (props) => {
    const materials = useAppSelector(selectMaterials);

    return (
        <Accordion defaultActiveKey="0">
            {props.model.materials.map((m, i) =>
                <Accordion.Item key={i} eventKey={m.name}>
                    <Accordion.Header>{m.name}</Accordion.Header>
                    <Accordion.Body>
                        <MaterialsRoundSelectorGrid materials={m.variations.map(mat => materials.find(e => e.id === mat)!)} onMaterialSelected={(materialId) => props.onMaterialSelected(i, materialId)} selected={props.selected.get(i)} />
                    </Accordion.Body>
                </Accordion.Item>
            )}
        </Accordion>
    )
}