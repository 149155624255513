import { configureStore, ThunkAction, Action, AnyAction, Reducer } from '@reduxjs/toolkit';
import collectionsReducer, { CollectionsState } from './features/collections/collectionsSlice';
import authReducer from './features/auth/authSlice';
import { undoable } from 'redux-undo-action';
import modelsReducer, { ModelsState } from './features/models/modelsSlice';
import materialsReducer, { MaterialsState } from './features/materials/materialsSlice';

export const store = configureStore({
  reducer: {
    collections: undoable(collectionsReducer) as Reducer<CollectionsState, AnyAction>,
    models: undoable(modelsReducer) as Reducer<ModelsState, AnyAction>,
    materials: undoable(materialsReducer) as Reducer<MaterialsState, AnyAction>,
    auth: authReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
