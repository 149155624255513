import { Card, Col, Container, Row } from "react-bootstrap";
import { ModelInfo } from "../../app/features/models/modelsSlice";
import * as Icon from 'react-bootstrap-icons';
import { HabitatLoader } from "../common/HabitatLoader";
import { useCallback, useEffect, useRef, useState } from "react";
import QRCodeService, { QRCodeModel } from "../../services/qr.service";

export interface ModelQRCodesProps {
    model: ModelInfo;
}

const ModelQRCodesTab: React.FC<ModelQRCodesProps> = (props) => {
    const [loadInProgress, setLoadInProgress] = useState(true);
    const [qrCodes, setQRCodes] = useState([] as QRCodeModel[]);
    const infoLoaded = useRef(false);

    const loadQRCodes = () => {
        QRCodeService.getModelQRCodes(props.model.id).then(data => {
            setQRCodes(data);
            setLoadInProgress(false);
        });
    }

    const createQRCode = () => {
        setLoadInProgress(true);
        QRCodeService.createModelQRCode(props.model.id).then(data => {
            const codes = qrCodes;
            codes.push(data);
            setQRCodes(codes);
            setLoadInProgress(false);
        });
    }

    const downloadQR = (qrId: string) => {
        const elm = document.createElement("a");
        elm.href = QRCodeService.getQRCode(qrId);
        elm.download = "qrcode-" + qrId;
        elm.click();
    };

    useEffect(() => {
        if (!infoLoaded.current) {
            infoLoaded.current = true;
            loadQRCodes();
        }
    }, [props.model]);

    return (
        <>
            <Container>
                <Row className="border-bottom mb-2 pb-2">
                    <Col md={9}>
                        <h2>Códigos QR:</h2>
                    </Col>
                    <Col md={3}>
                        <button className="btn btn-outline-success float-right" onClick={createQRCode}> Crear QR <Icon.PlusCircle /></button>
                    </Col>
                </Row>
                {loadInProgress && <HabitatLoader />}
                {!loadInProgress && <>
                    <Row>
                        {
                            qrCodes.map((qrCode) => (
                                <Col md={3} className="mb-3">
                                    <Card className="pb-3 pt-2">
                                        <Card.Img variant="top" src={QRCodeService.getQRCode(qrCode.id)} />
                                        <Card.Body>
                                            <p style={{ fontSize: "8px", textAlign: "center" }}><b>ID</b>: {qrCode.id} <span style={{ fontSize: "11px" }}><Icon.Download style={{ cursor: "pointer" }} onClick={() => downloadQR(qrCode.id)} /></span></p>
                                            <button className="btn btn-outline-success btn-block">Estadísticas</button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </>}
            </Container>
        </>
    )

}

export default ModelQRCodesTab;