import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { CategoryInfo, createCategoryAction, selectCategories } from "../../app/features/collections/collectionsSlice";
import * as Icon from 'react-bootstrap-icons';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useState } from "react";
import CategoryDetails from "./CategoryDetails";

interface CategoryListProps {
    collection_id: string;
}

const CategoryList: React.FC<CategoryListProps> = (props) => {
    const dispatch = useAppDispatch();
    const allCategories = useAppSelector(selectCategories);
    const categories = allCategories.filter(c => c.collectionId === props.collection_id);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [creatingCategory, setCreatingCategory] = useState(false);

    const handleCreateCategory = async () => {
        setCreatingCategory(true);
        await dispatch(createCategoryAction({ name: "Nueva Categoría", collection_id: props.collection_id })).unwrap();
        setCreatingCategory(false);
    }

    return (
        <span>
            {selectedCategory == -1 && <>
                <Row>
                    <Col xs={5}><h3>Categorias:</h3></Col>
                    <Col xs={7}><button disabled={creatingCategory} onClick={handleCreateCategory} className="btn btn-outline-success float-right">Añadir Categoría <Icon.PlusCircle /></button></Col>
                </Row>
                {categories.length > 0 &&
                    <ListGroup variant="flush">
                        {categories.map((c, i) =>
                            <ListGroup.Item key={c.id} action onClick={() => setSelectedCategory(i)}>
                                {c.name}
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                }
                {categories.length === 0 && <p>No se han encontrado categorías.</p>}
            </>}
            {selectedCategory != -1 && <>
                <Row>
                    <CategoryDetails categoryInfo={categories[selectedCategory]} onClickReturn={() => setSelectedCategory(-1)}/>
                </Row>
            </>}
        </span>
    )
}

export default CategoryList;